@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

html { 
  overflow-y: visible !important;
  padding-right: 0px !important;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  overflow-x: hidden !important;
  font-family: "League Spartan", sans-serif !important;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(214, 238, 247) 100%);
}

.logo {
  height: 6rem;
}

@media (max-width: 575.98px) { 
  .logo {
    height: 3.5rem;
  }
}

.custom-heading {
  font-size: 6rem;
  line-height: 85%;
  color: #ffffff;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .custom-heading {
    font-size: 5.5rem;
  }
}

@media (max-width: 576px) {
  .custom-heading {
    font-size: 3.5rem;
  }
}

.accordion-button {
  background-color: white !important;
  color: black !important;
}

.accordion-button:focus, 
.accordion-button:active, 
.accordion-button:focus-visible, 
.accordion-button:not(.collapsed):focus, 
.accordion-button:not(.collapsed):active, 
.accordion-button.collapsed:focus, 
.accordion-button.collapsed:active {
  background-color: white !important;
  color: black !important;
  outline: none;
  box-shadow: none;
}

.video-main {
  border-radius: 0.5rem;
  position: absolute;
  filter: brightness(50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-main {
  visibility: hidden;
  position: absolute;
  filter: brightness(50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 576px) {
  .video-main {
    visibility: hidden;
  }

  .image-main {
    visibility: visible;
  }

}

.column-custom {
  height: 43rem;
}

@media (max-width: 576px) {
  .column-custom {
    height: 34rem;
  }
}

.contact-button-main {
  background: #4D3B27 !important;
  color: white !important;
  border: none !important;
}

.contact-button-main:hover {
  background: #66bdd3 !important;
  border: none !important;
}

.contact-button:hover {
  background: #69ccf0 !important;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
}

.image-grid {
  width: 100%;
  height: auto;
}

.item1 { grid-area: 1 / 1 / 2 / 2; }
.item2 { grid-area: 2 / 1 / 3 / 2; }
.item3 { grid-area: 1 / 2 / 3 / 3; }

@media (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
  .item1 { order: 1; grid-area: unset; }
  .item3 { order: 2; grid-area: unset; }
  .item2 { order: 3; grid-area: unset; }
}

.hover-effect:hover {
  background: #ffffffbd !important;
}

.video {
  border-radius: 0.5px;
  width: 100%;
  height: 22rem;
}

@media (max-width: 576px) {
  .video {
    height: 17rem;
  }
}

.row-width {
  width: 75%;
}

@media (max-width: 767px) {
  .row-width {
    width: 100% !important;
  }
}
